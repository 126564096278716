import classNames from 'classnames'
import { Link } from 'react-router-dom'

const NavLink = ({ title, to, selected }) => (
  <li className="block">
    <Link
      to={to}
      className={classNames(
        'text-lg lg:text-2xl font-medium block pb-1.5 lg:pb-3 border-b-2 lg:border-b-[0.1875rem]',
        selected ? 'border-white' : 'border-transparent'
      )}
    >
      {title}
    </Link>
  </li>
)

const Navigation = ({ selectedLink }: { selectedLink: 'sales-hub' | 'parts-hub' }) => (
  <ul className="flex justify-center gap-x-6">
    <NavLink to="../parts-hub" selected={selectedLink === 'parts-hub'} title="Parts Hub" />
    <NavLink to="../sales-hub" selected={selectedLink === 'sales-hub'} title="Sales Hub" />
  </ul>
)

export default Navigation
