import { ReactNode } from 'react'

const ProductIntroTitle = ({ children }: { children: ReactNode }) => (
  <div className={'px-3 py-1.5 inline-block rounded-lg bg-orange-200'}>
    <h3 className="font-bold text-orange-900 text-base xl:text-lg leading-5 xl:leading-6 uppercase">
      {children}
    </h3>
  </div>
)

export default ProductIntroTitle
