import bgTop from './ProductIntro/bg_top.svg'
import bgBottom from './ProductIntro/bg_bottom.svg'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import Field from './ProductIntro/Field'
import Visibility from './ProductIntro/Visibility'
import Communication from './ProductIntro/Communication'

const ProductIntro = () => (
  <>
    <LayoutContainer
      className="pt-16 sm:pt-32 bg-orange-50 product-intro-top-bg"
      style={{
        backgroundImage: `url(${bgTop})`,
      }}
    />

    <LayoutContainer
      className="pb-16 bg-no-repeat bg-orange-50"
      style={{
        backgroundImage: `url(${bgBottom})`,
        backgroundPosition: 'center 100.1%',
        backgroundSize: '101vw',
      }}
      childrenClassName="space-y-12 sm:space-y-16"
    >
      <div className="mx-auto max-w-[64rem] space-y-3 sm:space-y-6">
        <h2 className="text-3xl leading-9 xl:text-5xl xl:leading-[3.25rem] font-medium text-center mx-auto">
          Parts don&apos;t have to be a pain in your asset management
        </h2>
        <p className="text-lg text-center mx-auto">
          Win time for your team and reduce costs for your fleet with the procurement platform built
          for construction fleet teams.
        </p>
      </div>

      <Field />
      <Visibility />
      <Communication />
    </LayoutContainer>
  </>
)

export default ProductIntro
