const Cog = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.35 8.634C26.498 5.122 21.502 5.122 20.65 8.634C20.098 10.904 17.498 11.98 15.506 10.764C12.418 8.884 8.886 12.418 10.766 15.504C11.0486 15.9675 11.2178 16.4912 11.2597 17.0324C11.3017 17.5737 11.2153 18.1172 11.0075 18.6187C10.7997 19.1202 10.4764 19.5656 10.064 19.9186C9.65156 20.2716 9.1616 20.5222 8.634 20.65C5.122 21.502 5.122 26.498 8.634 27.35C9.16113 27.4782 9.65057 27.7289 10.0625 28.0819C10.4745 28.4348 10.7974 28.88 11.0049 29.3812C11.2125 29.8825 11.2988 30.4256 11.257 30.9665C11.2151 31.5073 11.0462 32.0307 10.764 32.494C8.884 35.582 12.418 39.114 15.504 37.234C15.9675 36.9514 16.4912 36.7822 17.0324 36.7403C17.5737 36.6983 18.1172 36.7847 18.6187 36.9925C19.1202 37.2003 19.5656 37.5236 19.9186 37.936C20.2716 38.3484 20.5222 38.8384 20.65 39.366C21.502 42.878 26.498 42.878 27.35 39.366C27.4782 38.8389 27.7289 38.3494 28.0819 37.9375C28.4348 37.5255 28.88 37.2026 29.3812 36.9951C29.8825 36.7875 30.4256 36.7012 30.9665 36.743C31.5073 36.7849 32.0307 36.9538 32.494 37.236C35.582 39.116 39.114 35.582 37.234 32.496C36.9514 32.0325 36.7822 31.5088 36.7403 30.9676C36.6983 30.4263 36.7847 29.8828 36.9925 29.3813C37.2003 28.8798 37.5236 28.4344 37.936 28.0814C38.3484 27.7284 38.8384 27.4778 39.366 27.35C42.878 26.498 42.878 21.502 39.366 20.65C38.8389 20.5218 38.3494 20.2711 37.9375 19.9181C37.5255 19.5652 37.2026 19.12 36.9951 18.6188C36.7875 18.1175 36.7012 17.5744 36.743 17.0335C36.7849 16.4927 36.9538 15.9693 37.236 15.506C39.116 12.418 35.582 8.886 32.496 10.766C32.0325 11.0486 31.5088 11.2178 30.9676 11.2597C30.4263 11.3017 29.8828 11.2153 29.3813 11.0075C28.8798 10.7997 28.4344 10.4764 28.0814 10.064C27.7284 9.65156 27.4778 9.1616 27.35 8.634Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.2426 28.2426C29.3679 27.1174 30 25.5913 30 24C30 22.4087 29.3679 20.8826 28.2426 19.7574C27.1174 18.6321 25.5913 18 24 18C22.4087 18 20.8826 18.6321 19.7574 19.7574C18.6321 20.8826 18 22.4087 18 24C18 25.5913 18.6321 27.1174 19.7574 28.2426C20.8826 29.3679 22.4087 30 24 30C25.5913 30 27.1174 29.3679 28.2426 28.2426Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Cog
