import useToggle from '@/gf/hooks/useToggle'
import useSession from '@/retail/hooks/useSession'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import CloseIcon from './Header/CloseIcon'
import HamburguerIcon from './Header/HamburguerIcon'
import logo from './Header/logo.png'
import UserDropdown from './Header/UserDropdown'
import UserMenuItems from './Header/UserMenuItems'
import LayoutContainer from './LayoutContainer'
import LinkButton from './LinkButton'
import SiteUrls from '@/retail/modules/SiteUrls'

const NavLinks = ({
  className,
  mobileMenu = false,
}: {
  className?: string
  mobileMenu?: boolean
}) => {
  const menuItemClassName = mobileMenu
    ? 'block text-lg leading-7 p-4 border-b last:border-0 font-medium'
    : 'text-base leading-6'

  return (
    <nav className={classNames(mobileMenu && 'rounded-xl bg-white shadow-md', className)}>
      <a href="/parts-hub" className={menuItemClassName}>
        Parts Hub <span className="sm:hidden lg:inline-block">Pro</span>
      </a>
      <a href="/sales-hub" className={menuItemClassName}>
        Sales Hub <span className="sm:hidden lg:inline-block">Pro</span>
      </a>
      <a href="/pricing" className={menuItemClassName}>
        Pricing
      </a>
      <a href="/about" className={menuItemClassName}>
        About
      </a>
    </nav>
  )
}

const MobileMenu = ({ open }) => {
  const session = useSession()

  return (
    <motion.div
      animate={open ? 'open' : 'closed'}
      variants={{
        open: {
          opacity: 1,
          display: 'flex',
          scale: 1,
        },
        closed: {
          opacity: 0.5,
          display: 'none',
          scale: 0.9,
        },
      }}
      tabIndex={-1}
      className={classNames(
        'fixed inset-x-0 bottom-0 top-12 flex-col p-4 bg-gray-50 gap-y-4 overflow-y-auto pb-24 overscroll-contain'
      )}
    >
      <NavLinks mobileMenu />

      {session?.user ? (
        <UserMenuItems mobile />
      ) : (
        <div className="flex flex-col items-center gap-y-4">
          <LinkButton href={SiteUrls.BUYER_SCHEDULE_DEMO_URL}>Get Started</LinkButton>
          <a
            href="/login"
            className="font-medium text-orange-500 inline-block py-2 px-4 text-sm leading-5"
          >
            Sign In
          </a>
        </div>
      )}
    </motion.div>
  )
}

const Header = () => {
  const [mobileMenuOpen, mobileMenuToggle] = useToggle()
  const session = useSession()

  return (
    <LayoutContainer childrenClassName="px-0" className="bg-white sticky top-0 z-20">
      <header
        className={classNames(
          'px-4 sm:px-0 py-2 sm:py-6 gap-x-0 sm:gap-x-8 lg:gap-x-12 flex items-center sm:items-end ',
          !mobileMenuOpen && 'shadow-md sm:shadow-none'
        )}
      >
        <h1 className="flex-shrink-0">
          <a href="/">
            <img
              src={logo}
              alt="Gearflow"
              className="w-[6.25rem] h-4 sm:w-[8.68rem] sm:h-6 object-contain"
            />
          </a>
        </h1>

        <NavLinks className="hidden sm:flex gap-x-8 lg:gap-x-12 items-end flex-grow" />

        <div className="flex items-center justify-end flex-grow sm:flex-initial gap-x-6">
          {session ? (
            session.user ? (
              <UserDropdown />
            ) : (
              <>
                <a
                  href={SiteUrls.BUYER_SCHEDULE_DEMO_URL}
                  className="hidden sm:inline-block text-base font-medium py-1.5 px-3 rounded-md text-white border bg-orange-500 border-orange-500 -my-3"
                >
                  Get Started
                </a>

                <a
                  href="/login"
                  className="hidden sm:inline-block text-base leading-6 items-center "
                >
                  Sign In
                </a>
              </>
            )
          ) : null}

          <button
            type="button"
            className="inline-flex justify-center items-center sm:hidden w-8 h-8"
            onClick={mobileMenuToggle.toggle}
          >
            {mobileMenuOpen ? <CloseIcon /> : <HamburguerIcon />}
          </button>
        </div>

        <MobileMenu open={mobileMenuOpen} />
      </header>
    </LayoutContainer>
  )
}

export default Header
