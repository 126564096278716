import { AnchorHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type TertiaryProps = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href' | 'target' | 'rel' | 'children' | 'className'
>

export type ButtonProps = TertiaryProps & { size?: 'sm' | 'md' | 'lg'; color?: 'blue' | 'orange' }

const buttonClasses = {
  base: 'inline-block border font-normal rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
  primary: (color: ButtonProps['color']) =>
    twMerge(
      'text-white border-transparent',
      color === 'orange' &&
        'bg-orange-500 border-orange-600 hover:bg-orange-700 hover:border-orange-900 focus:ring-orange-500',
      color === 'blue' &&
        'bg-blue-600 border-blue-700 hover:bg-blue-700 hover:border-blue-800 focus:ring-blue-500'
    ),
  secondary: 'text-gray-700 border-gray-300 bg-white hover:bg-gray-50 focus:ring-yellow-500',
  sm: 'px-2.5 py-1.5 text-xs',
  md: 'px-4 py-2 text-sm',
  lg: 'px-6 py-3 text-base',
}

const Tertiary = forwardRef<HTMLAnchorElement, TertiaryProps>(
  ({ children, className, ...props }, ref) => (
    <a
      {...props}
      className={twMerge('text-blue-500 underline underline-offset-2', className)}
      ref={ref}
    >
      {children}
    </a>
  )
)

const Secondary = forwardRef<HTMLAnchorElement, ButtonProps>(
  ({ children, size = 'md', className, ...props }, ref) => (
    <a
      {...props}
      className={twMerge(
        buttonClasses.base,
        buttonClasses.secondary,
        buttonClasses[size],
        className
      )}
      ref={ref}
    >
      {children}
    </a>
  )
)

const Primary = forwardRef<HTMLAnchorElement, ButtonProps>(
  ({ children, size = 'md', color = 'orange', className, ...props }, ref) => (
    <a
      {...props}
      className={twMerge(
        buttonClasses.base,
        buttonClasses.primary(color),
        buttonClasses[size],
        className
      )}
      ref={ref}
    >
      {children}
    </a>
  )
)

export default { T: Tertiary, S: Secondary, P: Primary }
