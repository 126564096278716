import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import bgImg from './CaseOfSuccess/bg_img.svg'
import dan from './CaseOfSuccess/dan.png'
import ajaxLogo from '@/retail/components/Customers/ajax.png'
import { motion } from 'framer-motion'

const SnippetBase: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <motion.p
    className="flex flex-col gap-y-1 sm:gap-y-3"
    variants={{
      visible: {
        opacity: 1,
        scale: 1,
        transition: { type: 'spring', stiffness: 300, damping: 24 },
      },
      hidden: { opacity: 0, scale: 0.7, transition: { duration: 0.2 } },
    }}
  >
    {children}
  </motion.p>
)

const Snippet = ({ content, caption }) => (
  <SnippetBase>
    <strong className="text-4xl xl:text-6xl leading-8 xl:leading-[3.25rem] font-medium">
      {content}
    </strong>
    <span className="text-base xl:text-xl">{caption}</span>
  </SnippetBase>
)

const CaseOfSuccess = () => (
  <LayoutContainer
    style={{
      backgroundImage: `url(${bgImg})`,
    }}
    className="bg-gray-100 sm:bg-transparent case-of-success-top-bg"
    childrenClassName="sm:py-12 xl:py-16"
  >
    <section className="bg-transparent sm:bg-gray-100 sm:shadow-md sm:rounded-xl py-12 xl:py-24 xl:px-8 flex flex-col gap-y-8 xl:gap-y-16">
      <h2 className="text-3xl sm:text-5xl sm:leading-[3.25rem] sm:text-center font-medium">
        Ajax turns parts process into margin maker
      </h2>

      <div className="flex flex-col sm:flex-row gap-x-16 gap-y-8 justify-center">
        <div className="sm:order-2">
          <motion.div
            className="mt-4 xl:mt-8 space-y-6 xl:space-y-12"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delayChildren: 0.2, staggerChildren: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <Snippet
              content="6x faster"
              caption="avg fulfillment time - from 6 days to under 24 hrs"
            />
            <Snippet content="$350k" caption="saved in parts spend year over year" />
            <Snippet content="4 hrs/day" caption="saved for the inventory control specialist" />
            <SnippetBase>
              <a
                className="text-base lg:text-lg xl:text-xl text-gearflow underline decoration-gearflow decoration-[0.12rem] motion-safe:transition-all motion-safe:duration-200 hover:decoration-[0.18rem] focus:decoration-[0.18rem]"
                href="https://landing-page.gearflow.com/how-ajax-won-back-margin-in-parts-process"
              >
                Read the full story here
              </a>
            </SnippetBase>
          </motion.div>
        </div>

        <blockquote className="space-y-6 xl:space-y-8 sm:max-w-[26rem] xl:max-w-[36.68rem] sm:order-1">
          <p className="text-xl xl:text-2xl leading-7 xl:leading-8">
            “We&apos;re a budgeted cost center, so we want to be predictable with our costs.
            It&apos;s a trickle-down effect that if we save money here, we can lower our internal
            burden on our estimating departments, so they can secure more jobs, and we can make more
            money.
          </p>
          <p className="text-xl xl:text-2xl leading-7 xl:leading-8">
            That&apos;s why I was attracted to Parts Hub Pro, because parts procurement is the final
            frontier for fleet management to solve.”
          </p>
          <footer className="flex items-end">
            <img
              src={dan}
              alt="Dan Maitland"
              className="rounded-full w-[5rem] h-[5rem]  xl:w-[9.375rem] xl:h-[9.375rem]"
            />
            <div className="flex items-center -ml-4 sm:-ml-7 gap-x-3">
              <img
                src={ajaxLogo}
                alt="Ajax Paving Industries of Florida Logo"
                className="h-[3rem] xl:h-[5.31rem]"
              />
              <div>
                <p className="text-lg sm:text-xl leading-6 sm:leading-7">
                  <strong className="font-medium">Dan Maitland,</strong>{' '}
                  <span className="">CEM</span>
                </p>
                <p className="text-sm xl:text-base xl:leading-6">
                  Fleet Manager, Ajax Paving Industries of Florida
                </p>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </section>
  </LayoutContainer>
)

export default CaseOfSuccess
