import { Link } from 'react-router-dom'
import { ShoppingCartIcon } from '@heroicons/react/outline'

import useSession from '../../hooks/useSession'

import NavButtonA from '../../pages/Home/NavButton'
import Container from './Container'
import Search from '../Search'
import MobileNav from './Mobile/Nav'
import WhoWeServe from './WhoWeServe'
import ShopNow from './ShopNow'
import Store from './Store'
import User from './User'

import logo from './logo.png'
import SiteUrls from '@/retail/modules/SiteUrls'

const styles = {
  navItem: `inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 whitespace-nowrap`,
}

const Header = ({ searchQuery }: { searchQuery?: string }) => {
  const session = useSession()

  return (
    <header className="py-10">
      <Container>
        <div className="space-y-4">
          <nav className="relative z-50 flex gap-8 xl:gap-12 justify-between lg:justify-start">
            <div className="flex items-center md:gap-x-8">
              <Link to="/" aria-label="Home">
                <img src={logo} alt="Gearflow" className="w-36" />
              </Link>
              <div className="hidden md:flex md:gap-x-2">
                <WhoWeServe />
                <ShopNow />
              </div>
            </div>
            <div className="hidden lg:block grow">
              <Search query={searchQuery} />
            </div>
            <div className="flex items-center gap-x-5 md:gap-x-2">
              <Store />
              <User />

              {session && !session.user && (
                <>
                  <a href="/login" className={`${styles.navItem} hidden md:block`}>
                    Sign In
                  </a>
                  <NavButtonA
                    href={SiteUrls.BUYER_SCHEDULE_DEMO_URL}
                    color="gearflow"
                    className="whitespace-nowrap"
                  >
                    Get Started
                  </NavButtonA>
                </>
              )}

              <a href="/cart" className={`${styles.navItem} hidden md:flex items-center gap-1`}>
                <ShoppingCartIcon className="h-6 w-6" />
                {session && (
                  <div className="text-xs bg-gearflow text-white px-2 py-1 rounded-full font-bold">
                    {session.cartCount}
                  </div>
                )}
              </a>

              <div className="-mr-1 md:hidden">
                <MobileNav />
              </div>
            </div>
          </nav>
          <div className="lg:hidden">
            <Search query={searchQuery} />
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header
