import field from './Field/field.png'
import { motion } from 'framer-motion'
import ProductIntroTitle from './ProductIntroTitle'

const title = 'Parts info at your fingertips'

const Field = () => (
  <motion.section className="grid grid-cols-1 sm:grid-cols-[auto_22rem] xl:grid-cols-[auto_33rem] items-center gap-y-8 sm:gap-x-12 xl:gap-x-16">
    <figure className="sm:hidden order-2 relative w-full">
      <div className="relative w-[130vw] -left-[35vw]">
        <img src={field} className="w-full h-full object-cover" alt={title} />
      </div>
    </figure>
    <motion.figure className="relative h-[29rem] xl:h-[39rem] mt-4 sm:mt-0 hidden sm:block order-1">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-200px 1px' }}
        transition={{ duration: 0.5 }}
        variants={{
          visible: {
            opacity: 1,
            x: 0,
            transition: { type: 'spring', stiffness: 300, damping: 24 },
          },
          hidden: { opacity: 0, x: -200 },
        }}
        className="hidden sm:block absolute inset-y-0 w-[36rem] xl:w-[48rem] right-0"
      >
        <img src={field} className="" alt={title} />
      </motion.div>
    </motion.figure>
    <div className="pb-auto sm:pb-6 xl:pb-10 space-y-4 sm:space-y-8 w-full sm:w-[20rem] xl:w-[34.75rem] text-center sm:text-left order-1 sm:order-2">
      <div className="space-y-2 sm:space-y-4">
        <div className="flex justify-center sm:justify-start">
          <ProductIntroTitle>For the Field</ProductIntroTitle>
        </div>
        <h3 className="font-medium text-xl sm:text-3xl leading-6 sm:leading-9">{title}</h3>
      </div>
      <p className="text-base sm:text-lg leading-6 sm:leading-7">
        Easy access to the parts information you need, so you can focus on fixing equipment.
      </p>
    </div>
  </motion.section>
)

export default Field
