import SiteUrls from '@/retail/modules/SiteUrls'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import LinkButton from '../../components/LayoutNext/LinkButton'
import Cog from './WeMakeItEasier/Cog'
import Dollar from './WeMakeItEasier/Dollar'
import LightBulb from './WeMakeItEasier/LightBulb'
import Tag from './WeMakeItEasier/Tag'

const Info = ({ icon: Icon, title, subtitle, text }) => (
  <div className="flex flex-col items-start sm:flex-row gap-4">
    <div className="hidden sm:flex flex-shrink-0">
      <Icon className="w-12 h-12 text-white" />
    </div>
    <div className="space-y-2 xl:space-y-4">
      <div className="flex flex-col space-y-2 items-start">
        <div className="inline-flex flex-row justify-start items-center gap-x-1.5">
          <div className="flex sm:hidden flex-shrink-0">
            <Icon className="w-10 h-10 text-white" />
          </div>
          <div className={'px-3 py-1.5 inline-block rounded-lg bg-orange-600'}>
            <h3 className="font-bold text-base xl:text-lg leading-5 xl:leading-6 uppercase">
              {title}
            </h3>
          </div>
        </div>
        <h3 className="font-medium text-lg xl:text-2xl leading-7 xl:leading-8">{subtitle}</h3>
      </div>
      <p className="text-sm xl:text-lg leading-6 xl:leading-7 text-left">{text}</p>
    </div>
  </div>
)

const WeMakeItEasier = () => (
  <LayoutContainer className="bg-orange-500 text-white">
    <section className="pt-16 pb-16 xl:pt-24 xl:pb-16 flex flex-col sm:flex-row gap-y-8 xl:gap-x-16">
      <div className="w-full sm:max-w-[31.5rem] space-y-8">
        <div className="space-y-1">
          <p className="text-center sm:text-left text-base xl:text-xl">
            Solutions built for each role on your team
          </p>
          <h2 className="text-center sm:text-left font-medium text-3xl xl:text-5xl leading-9 xl:leading-[3.25rem]">
            Parts is a team sport
          </h2>
        </div>
        <LinkButton
          href={SiteUrls.BUYER_SCHEDULE_DEMO_URL}
          variation="secondary"
          className="hidden sm:inline-block"
        >
          Schedule a Demo
        </LinkButton>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-8 xl:gap-y-16">
        <Info
          icon={Cog}
          title="Technicians"
          subtitle="Your field-tested parts sidekick"
          text="The simplest way to lookup parts, track orders, and talk to your team & vendors. All from your phone or tablet."
        />
        <Info
          icon={Dollar}
          title="Purchasing Managers"
          subtitle="A single parts command center"
          text="Manage every purchase across your organization from a single dashboard, and reclaim the time in your day."
        />
        <Info
          icon={LightBulb}
          title="Fleet Managers"
          subtitle="Insights that reduce your maintenance costs"
          text="Gain complete visibility into your purchasing, people, and process so you can add to your bottom line."
        />
        <Info
          icon={Tag}
          title="Vendors"
          subtitle="The simplest way to service your customers"
          text="Service your customers in half the time with the only free software built for your parts desk."
        />
      </div>
      <div className="sm:hidden flex justify-center py-4">
        <LinkButton href={SiteUrls.BUYER_SCHEDULE_DEMO_URL} variation="secondary">
          Schedule a Demo
        </LinkButton>
      </div>
    </section>
  </LayoutContainer>
)

export default WeMakeItEasier
