import { motion } from 'framer-motion'
import visibility from './Visibility/visibility.png'
import ProductIntroTitle from './ProductIntroTitle'

const title = 'Visibility into every purchase'

const Visibility = () => (
  <motion.section className="grid grid-cols-1 sm:grid-cols-[22rem_auto] xl:grid-cols-[33rem_auto] items-center gap-y-8 sm:gap-x-12 xl:gap-x-16">
    <div className="pb-auto sm:pb-10 xl:pb-16 space-y-4 sm:space-y-8 w-full sm:w-[22rem] xl:w-[34.75rem] text-center sm:text-left">
      <div className="space-y-2 sm:space-y-4">
        <div className="flex justify-center sm:justify-start">
          <ProductIntroTitle>For the Office</ProductIntroTitle>
        </div>
        <h3 className="font-medium text-xl sm:text-3xl leading-6 sm:leading-9">{title}</h3>
      </div>
      <p className="text-base sm:text-lg leading-6 sm:leading-7">
        Know every part being purchased and why, so you can cut unnecessary costs.
      </p>
    </div>

    <figure className="sm:hidden relative w-full">
      <div className="relative w-[111vw] right-[12vw]">
        <img src={visibility} className="w-full h-full object-cover" alt={title} />
      </div>
    </figure>

    <motion.figure className="relative hidden sm:block h-[29rem] xl:h-[40rem] mt-4 sm:mt-0">
      <motion.div className="hidden sm:block absolute inset-y-0 w-[45.25rem] -left-[1rem] xl:w-[62.25rem] xl:-left-[2rem]">
        <motion.img
          src={visibility}
          className="object-contain"
          alt={title}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: '-200px 1px' }}
          transition={{ duration: 0.5 }}
          variants={{
            visible: {
              opacity: 1,
              x: 0,
              transition: { type: 'spring', stiffness: 300, damping: 24 },
            },
            hidden: { opacity: 0, x: 200 },
          }}
        />
      </motion.div>
    </motion.figure>
  </motion.section>
)

export default Visibility
