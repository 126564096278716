import gearBg from '@/retail/components/svgs/gear_bg.svg'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import LinkButton from '../../components/LayoutNext/LinkButton'
import DesktopImage from './BookDemoHero/DesktopImage'
import showcasePicMobile from './BookDemoHero/showcase-pic-mobile.png'
import SiteUrls from '@/retail/modules/SiteUrls'

const BookDemoHero = () => {
  // const imageRef = useRef<HTMLDivElement>(null)
  // const { scrollYProgress } = useScroll({
  //   target: imageRef,
  //   offset: ['end end', 'end start'],
  // })

  // const opacity = useTransform(scrollYProgress, [0.2, 1], [1, 0.5])
  // const scale = useTransform(scrollYProgress, [0.2, 1], [1, 0.7])

  return (
    <LayoutContainer
      className="home-gear-bg"
      style={{
        backgroundImage: `url(${gearBg})`,
      }}
    >
      <section className="flex flex-col sm:flex-row sm:gap-x-3 gap-y-16 sm:gap-y-0 items-baseline sm:items-center pt-8 sm:pt-16 pb-8 sm:pb-20 xl:pb-32">
        <div className="flex-grow space-y-8">
          <h2 className="font-medium text-3xl xl:text-5xl leading-9 xl:leading-[3.25rem]">
            <span className="block">The A.I. powered</span>
            <span className="block">parts platform that</span>
            <span className="block font-bold">
              <strong>keeps jobs running</strong>
            </span>
          </h2>
          <p className="text-base sm:text-xl leading-6 sm:leading-7">
            Keep your equipment running and your maintenance <br className="hidden xl:block" />
            budget low with smarter parts management.
          </p>
          <div className="flex gap-x-4">
            <LinkButton href={SiteUrls.BUYER_SCHEDULE_DEMO_URL}>Schedule a Demo</LinkButton>
            <LinkButton href="/how-it-works" variation="secondary">
              Learn More
            </LinkButton>
          </div>
        </div>
        <figure className="w-full sm:w-[28rem] xl:w-[39rem] relative flex-shrink-0">
          <div className="sm:hidden relative w-[145vw] -left-[52vw]">
            <img
              src={showcasePicMobile}
              alt="Gearflow makes process better"
              className="w-full h-full object-cover"
            />
          </div>

          <DesktopImage />
        </figure>
      </section>
    </LayoutContainer>
  )
}

export default BookDemoHero
