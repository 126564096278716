import classNames from 'classnames'
import { motion } from 'framer-motion'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import SiteUrls from '../modules/SiteUrls'
import LayoutContainer from './LayoutNext/LayoutContainer'
import LinkButton from './LayoutNext/LinkButton'
import bottomBg from './PricingSection/bg_bottom.svg'

export enum PlanType {
  Basic = 0,
  Pro = 1,
  Enterprise = 2,
}

export type Plan = {
  ids: PlanType[]
  pretext?: string
  posttext?: ReactNode
  showFee: boolean
  isMostPopular?: boolean
  name: string
  desc: string
  highlights: string[]
  price: string
  priceLabel?: string
  ctaText: string
  ctaHref: string
}

export const partsHubPlans: Plan[] = [
  {
    ids: [PlanType.Basic],
    isMostPopular: false,
    showFee: true,
    name: 'Basic',
    desc: 'Source alternative options on parts from the Gearflow Supplier Network',
    highlights: [
      'Unlimited requests to the Gearflow Supplier Network',
      'Easy quote comparisons',
      'Instant messaging with your vendors',
      'Automated notifications to keep you informed',
    ],
    price: '$0 per user',
    ctaText: 'Sign Up Now',
    ctaHref: '/signup',
  },
  {
    ids: [PlanType.Pro, PlanType.Enterprise],
    isMostPopular: true,
    showFee: false,
    name: 'Parts Hub Pro',
    desc: 'Centralize all your purchasing across new & existing vendors',
    highlights: [
      'Everything included in the Basic Plan',
      'One place for all your vendors',
      'Get requests from your technicians with the Parts Hub Pro mobile app',
      'End-to-end collaboration tools',
      'Real-time cost, process, team, and vendor insights',
      'Integrations with your internal systems',
      'Specialized service from our expert team',
    ],
    price: 'Custom pricing',
    ctaText: 'Schedule a Demo',
    ctaHref: SiteUrls.BUYER_SCHEDULE_DEMO_URL,
    priceLabel: '',
  },
]

const salesHubPlans: Plan[] = [
  {
    ids: [PlanType.Basic],
    isMostPopular: false,
    name: 'Basic',
    desc: 'Win new business from Gearflow users looking for parts',
    highlights: [
      'Fulfill requests from new customers by joining the Gearflow Network',
      'Automated quoting tools',
      'Electronic payments & payouts',
      'Sales insights to measure performance',
    ],
    price: '$0 per user',
    posttext: (
      <>
        +12.65% transaction fee
        <br className="hidden sm:block" />
        on new business
      </>
    ),
    ctaText: 'Sign Up Now',
    ctaHref: '/signup/sales-hub',
    showFee: false,
  },
  {
    ids: [PlanType.Pro, PlanType.Enterprise],
    isMostPopular: true,
    name: 'Sales Hub Pro',
    desc: 'Convert more parts sales across all of your customers',
    highlights: [
      'Everything included in the Basic Plan',
      'Easily manage orders for new & existing customers in one place',
      'Invoice your customers on their current account & pricing',
      'Increase repeat business with customer-facing mobile app',
      'Advanced reporting insights',
      'Integrations with your internal systems',
      'Specialized service from our expert team',
    ],
    price: 'Custom pricing',
    ctaText: 'Schedule a Demo',
    ctaHref: SiteUrls.DEALER_SCHEDULE_DEMO_URL,
    priceLabel: '',
    showFee: false,
  },
]

const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className="flex-shrink-0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0484 6.35159C20.2734 6.57662 20.3997 6.88179 20.3997 7.19999C20.3997 7.51818 20.2734 7.82335 20.0484 8.04839L10.4484 17.6484C10.2234 17.8734 9.9182 17.9997 9.6 17.9997C9.2818 17.9997 8.97663 17.8734 8.7516 17.6484L3.9516 12.8484C3.73301 12.6221 3.61206 12.3189 3.61479 12.0043C3.61753 11.6897 3.74373 11.3887 3.96622 11.1662C4.18871 10.9437 4.48968 10.8175 4.80432 10.8148C5.11895 10.812 5.42208 10.933 5.6484 11.1516L9.6 15.1032L18.3516 6.35159C18.5766 6.12662 18.8818 6.00024 19.2 6.00024C19.5182 6.00024 19.8234 6.12662 20.0484 6.35159Z"
      fill="#111827"
    />
  </svg>
)

const Plan = ({
  pretext,
  posttext,
  showFee,
  isMostPopular = false,
  name,
  desc,
  highlights,
  price,
  priceLabel,
  ctaText,
  ctaHref,
}: Plan) => (
  <motion.section
    className={classNames(
      'rounded-xl lg:rounded-[1.25rem] shadow-lg flex flex-col flex-shrink-0 ',
      isMostPopular
        ? 'lg:w-[28.125rem] lg:h-[49.5rem] bg-blue-500 p-[0.3125rem]'
        : 'lg:w-[22.9375rem] lg:h-[40.625rem]'
    )}
    variants={{
      visible: {
        opacity: 1,
        translateX: 0,
        transition: { type: 'spring', stiffness: 300, damping: 40 },
      },
      hidden: { opacity: 0.0, translateX: -100, transition: { duration: 0.4 } },
    }}
  >
    <div className="flex-grow flex flex-col p-4 sm:p-8 bg-white rounded-xl lg:rounded-[1.25rem]">
      <hgroup className="space-y-2 sm:space-y-4">
        <h3 className="text-3xl sm:text-5xl font-medium leading-[2.5rem] sm:leading-[3.25rem]">
          {name}
        </h3>
        <p className="text-sm sm:text-base">{desc}</p>
      </hgroup>
      <ul className="mt-4 sm:mt-8 space-y-2 sm:space-y-6 flex-grow">
        {highlights.map((text) => (
          <li key={text} className="flex gap-x-2 sm:gap-x-4 text-sm sm:text-base">
            <CheckIcon /> {text}
          </li>
        ))}
      </ul>
      {pretext && <p className="text-xs sm:text-sm pt-10 lg:pt-0">{pretext}</p>}
      <p
        className={classNames(
          'font-medium text-xl sm:text-3xl',
          pretext ? 'pt-0' : 'pt-10 lg:pt-0'
        )}
      >
        {price}{' '}
        {priceLabel && <span className="text-sm md:text-base font-normal">{priceLabel}</span>}
      </p>
      {showFee && <p className="text-sm sm:text-base mt-1 sm:mt-2">+3% transaction fee</p>}
      {posttext && <p className="text-sm sm:text-base mt-1 sm:mt-2">{posttext}</p>}
      <LinkButton href={ctaHref} className="w-full mt-4 sm:mt-8">
        {ctaText}
      </LinkButton>
    </div>
  </motion.section>
)

const PricingSection = ({
  title,
  subtitle,
  plans,
  navigation,
  learnMore,
}: {
  title: string
  subtitle: string
  plans: Plan[]
  navigation?: ReactNode
  learnMore?: string
}) => (
  <LayoutContainer
    className="bg-orange-500 how-it-works-pricing-bg"
    id="pricing"
    style={{
      backgroundImage: `url(${bottomBg})`,
    }}
  >
    <section className="py-8 sm:py-16">
      <hgroup className="space-y-4 lg:space-y-8 text-white text-center pb-8">
        <h2 className="font-medium text-3xl lg:text-5xl">{title}</h2>
        {navigation}
        <p className="max-w-[38.625rem] text-base lg:text-lg mx-auto">
          {subtitle}{' '}
          {learnMore && (
            <Link to={learnMore} className="underline">
              Learn more
            </Link>
          )}
        </p>
      </hgroup>
      <motion.div
        className="flex flex-col lg:flex-row lg:items-center lg:justify-center gap-8 py-8"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.6, delayChildren: 0.2, staggerChildren: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        {plans.map((p) => (
          <Plan key={p.ids.join('-')} {...p} />
        ))}
      </motion.div>
    </section>
  </LayoutContainer>
)

export const PartsHubPricing = ({
  title = 'Work smarter by going Pro',
  navigation,
  showLearnMoreLink,
}: {
  title?: string
  navigation?: ReactNode
  showLearnMoreLink?: boolean
}) => (
  <PricingSection
    title={title}
    subtitle="Get everything your fleet team needs to better manage your parts process with the Parts Hub Pro Plan."
    plans={partsHubPlans}
    navigation={navigation}
    learnMore={showLearnMoreLink ? '/parts-hub' : undefined}
  />
)

export const SalesHubPricing = ({
  title = 'Close more parts business with Sales Hub Pro',
  navigation,
  showLearnMoreLink,
}: {
  title?: string
  navigation?: ReactNode
  showLearnMoreLink?: boolean
}) => (
  <PricingSection
    title={title}
    subtitle="Empower your parts desk with the only software built to connect fleet teams and their vendors."
    plans={salesHubPlans}
    navigation={navigation}
    learnMore={showLearnMoreLink ? '/sales-hub' : undefined}
  />
)
